import giaDotik from "../assets/image/png/projectCards/giaDotik.png";
import vizir from "../assets/image/png/projectCards/vizir.png";
import likeCrazy from "../assets/image/png/projectCards/likeCrazy.jpg";
import komet from "../assets/image/png/projectCards/komet.png";
import fotoklub from "../assets/image/png/projectCards/fotoklub.png";
import cesnovanje from "../assets/image/png/projectCards/cesnovanje.png";
import yumyums from "../assets/image/png/projectCards/yumyums.png";
import masaalom from "../assets/image/png/projectCards/masaalom.png";
import najmlajsi from "../assets/image/png/projectCards/najmlajsi.png";
import beequeen from "../assets/image/png/projectCards/beequeen.png";
import kowkow from "../assets/image/png/projectCards/kowkow.png";
import residentana from "../assets/image/png/projectCards/residentana.png";
import cokolatl from "../assets/image/png/projectCards/cokolatl.png";
import kmetija from "../assets/image/png/projectCards/kmetija.png";
import dpmojca from "../assets/image/png/projectCards/dpmojca.png";
import funky from "../assets/image/png/projectCards/funky.png";
import midori from "../assets/image/png/projectCards/midori.jpg";
import proslad from "../assets/image/png/projectCards/proslad.png";
import dyecare from "../assets/image/png/projectCards/dyecare.png";
import komunala from "../assets/image/png/projectCards/komunala.png";
import center from "../assets/image/png/projectCards/center.png";
import perlin from "../assets/image/png/projectCards/perlin.png";

export const designWorks1 = [
         {
           brand: `Pivovarna Vizir d.o.o.`,
           slug: `harmonicni-ples-okusov`,
           title: `Harmonični ples okusov! Glavne zvezde so hmeljčki, kapljice vode, kvasovke in ječmenov slad!`,
           categories: [`embalaža`, `cgp`],
           thumbnail: vizir,
           link: "/harmonicni-ples-okusov",
         },
         // {
         // 	brand: `MPZ Marketing Academy`,
         // 	slug: `barvito-interaktivno-in-zabavno-po-poti-ucenja-dobrega-tekstopisja-ki`,
         // 	title: `Barvito, interaktivno in zabavno na poti učenja dobrega tekstopisja, ki prodaja. Hitro! `,
         // 	categories: [`cgp`, 'oblikovanje'],
         // 	thumbnail: likeCrazy,
         // 	link:
         // 		'/barvito-interaktivno-in-zabavno-po-poti-ucenja-dobrega-tekstopisja-ki',
         // },
        //  {
        //    brand: `Čebelarstvo Muller`,
        //    slug: `oblikovanje-embalaze-beequeen-krema`,
        //    title: `Nežna senca na hrbtni strani embalaže prikazuje jutranji travnik in pripoveduje let zlate čebelice, ki leta nad rožami ustvarjene iz linij v logotipu.`,
        //    categories: [`embalaža`],
        //    thumbnail: beequeen,
        //    link: "/oblikovanje-embalaze-beequeen-krema",
        //  },
         {
           brand: `Beti d.o.o.`,
           slug: `logotip-dyecare`,
           title: `Odgovornost do okolja je glavno vodilo DyeCare. Vodna kapljica simbolizira trajnostno naravnanost podjetja.`,
           categories: ["cgp"],
           thumbnail: dyecare,
           link: "/logotip-dyecare",
         },
         {
           brand: `Rdeči križ Slovenije`,
           slug: `prva-pomoc-pri-najmlajsih-prirocnik`,
           title: `Prva pomoč pri najmlajših je priročnik za starše, ki kompleksno tematiko razlaga na bolj interaktiven način.`,
           categories: [`oblikovanje`],
           thumbnail: najmlajsi,
           link: "/prva-pomoc-pri-najmlajsih-prirocnik",
         },
         {
           brand: `Komet d.d. `,
           slug: `katalog-tiskovine-komet`,
           title: `S katalogom do moderne linije spodnjega perila za ženske, ki samozavestno negujejo žensko v sebi ...`,
           categories: [`oblikovanje`],
           thumbnail: komet,
           link: "/katalog-tiskovine-komet",
         },
        //  {
        //    brand: `Proslad d.o.o.`,
        //    slug: `oreh-je-heroj`,
        //    title: `Oreh je heroj prigrizkov. Če preberemo slovensko besedo oreh v nasprotni smeri, kot je pisana, preberemo h-e-r-o. Torej je oreh res, Hero's Snack.`,
        //    categories: [`cgp`, `embalaža`],
        //    thumbnail: proslad,
        //    link: "/oreh-je-heroj",
        //  },
         //  {
         //    brand: `Komunala Črnomelj`,
         //    slug: `osvezitev-nove-podobe`,
         //    title: `Osvežitev nove podobe zelenih in belih barv spominja na naravo in poslanstvo organizacije, ki skrbi, da je naša okolica lepša.`,
         //    categories: [`cgp`],
         //    thumbnail: komunala,
         //    link: "/osvezitev-nove-podobe",
         //  },
         {
           brand: `Center ponovne rabe`,
           slug: `simbol-recikliranje-simbol-narave`,
           title: `Simbol za recikliranje v združitvi s simbolom narave sporoča, kako ponovna uporaba pozitivno vpliva na naš planet.`,
           categories: [`cgp`],
           thumbnail: center,
           link: "/simbol-recikliranje-simbol-narave",
         },
         {
           brand: `Residenca Ana `,
           slug: `zelena-za-trajnostni-turizem`,
           title: `Katalog ustvarja vzdušje, da bo nastanitev v Boginji vasi prerodilo duha in navdušilo nad bolj trajnostnimi načini življenja.`,
           categories: [`oblikovanje`, "trajnostno"],
           thumbnail: residentana,
           link: "/zelena-za-trajnostni-turizem",
         },
         {
           brand: `Petrova kmetija`,
           slug: `kulinaricni-festival-tematiko`,
           title: `Kulinarični festival s tematiko belokranjskega česna po poteh trajnostnega razvoja! `,
           categories: [`cgp`, "oblikovanje", "trajnostno"],
           thumbnail: cesnovanje,
           link: "/kulinaricni-festival-tematiko",
         },
         {
           brand: `Masaalon`,
           slug: `dizajn-masaalon-zacimbe-zdruzuje`,
           title: `Dizajn za Masaalon začimbe združuje izvrsten okus slastnega chai toplega napitka: ingver, cimet, kardamom in črni poper.`,
           categories: [`embalaža`, "trajnostno"],
           thumbnail: masaalom,
           link: "/dizajn-masaalon-zacimbe-zdruzuje",
         },

         {
           brand: `Perlin, Maja Klakočar s.p.`,
           slug: `izvrstne-resitve`,
           title: `Izvrstne rešitve za vaš pobeg in pogled iz vode na Perlin Riverhouse, hišico razvidno iz logotipa.`,
           categories: [`cgp`],
           thumbnail: perlin,
           link: "/izvrstne-resitve",
         },

         {
           brand: `Sir Coco Archibald`,
           slug: `embalaza-za-cokolado1`,
           title: `Barvna lestvica kakavovca in simbol kakavovega zrna nas popeljejo v dnevno sanjarjenje sladke arome čokolade Kow Kow.`,
           categories: [`embalaža`],
           thumbnail: kowkow,
           link: "/embalaza-za-cokolado1",
         },

         {
           brand: `Patli Yaotl Inc.`,
           slug: `skrivnostna-staroazteska-kultura`,
           title: `Skrivnostna azteška kultura in njihovo čaščenje kakava nas poda v svet arome premium razreda.`,
           categories: [`embalaža`],
           thumbnail: cokolatl,
           link: "/skrivnostna-staroazteska-kultura",
         },
         {
           brand: `Milo Funky`,
           slug: `embalaza-milo`,
           title: `Funky Soup je igra besed, kjer v angleščini funky pomeni smrdljiv. Milnica v vzorcu ponazarja razigranost vonja Funky mila. `,
           categories: [`embalaža`],
           thumbnail: funky,
           link: "/embalaza-milo",
         },
         {
           brand: `Čaj Midori`,
           slug: `embalaža-japonska-kultura`,
           title: `Listi čajevca in skodelica v svojem minimalizmu predstavljajo japonske elemente, ki ustvarjajo spokojnost občutka ob pitju čaja.`,
           categories: [`embalaža`, "trajnostno"],
           thumbnail: midori,
           link: "/embalaža-japonska-kultura",
         },
         {
           brand: `Fotoklub Črnomelj`,
           slug: `najboljse-stvaritve-nastanejo`,
           title: `Najboljše stvaritve nastanejo ob popolni kreativni svobodi ...`,
           categories: [`oblikovanje`],
           thumbnail: fotoklub,
           link: "/najboljse-stvaritve-nastanejo",
         },
         {
           brand: `Masažni salon Gia`,
           slug: `umetnost-zlatega-dotika-je-slogan-ki-povzema-vse-dobrobiti-masaze-zdravilnih`,
           title: `Umetnost zlatega dotika je slogan, ki povzema vse dobrobiti masaže zdravilnih rok prisrčne maserke`,
           categories: [`cgp`, `oblikovanje`],
           thumbnail: giaDotik,
           link:
             "/umetnost-zlatega-dotika-je-slogan-ki-povzema-vse-dobrobiti-masaze-zdravilnih",
         },
         {
           brand: `Yum Yums`,
           slug: `barvite-ilustracije-vzorcu`,
           title: `Barvite ilustracije na vzorcu herringbone poudarja navdušenje nad bogatimi okusi sadežev jagode in limone. `,
           categories: [`embalaža`],
           thumbnail: yumyums,
           link: "/barvite-ilustracije-vzorcu",
         },
        //  {
        //    brand: `Ekološka Kmetija`,
        //    slug: `belokranjsko-vezenje-etiketi`,
        //    title: `Belokranjsko vezenje na etiketi poudarja lokalni izvor izdelkov. Podoba travnika nas spomni na naravno, zdravo in sveže.. `,
        //    categories: [`embalaža`],
        //    thumbnail: kmetija,
        //    link: "/belokranjsko-vezenje-etiketi",
        //  },
        //  {
        //    brand: `Društvo prijateljev mladine Mojca Dolenjske Toplice`,
        //    slug: `oblikovanje-celostne-graficne-podobe`,
        //    title: `Kjer voda, sonce in veselje otrok najdeta vedno svoje mesto!`,
        //    categories: [`cgp`],
        //    thumbnail: dpmojca,
        //    link: "/oblikovanje-celostne-graficne-podobe",
        //  },
       ];
